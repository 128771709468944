import { MaskOnBlur } from "utils";

export default {
    FRENCH: {
        DESCRIPTION: "Description",
        FREE_IN_N_HOURS_FROM: (days = 2, price = 300,symbol,precision) => `DÉLAI DE LIVRAISON ${days}j`,/*H À PARTIR DE ${MaskOnBlur(price,symbol,precision)} */
        ADD_TO_CART: 'Ajouter au panier',
        CART:"Panier",
        REFERENCE: (ref) => `Référence : ${ref}`,
        SIGN_IN: 'Connexion',
        ALREADY_HAVE_AN_ACCOUNT: 'Vous avez déjà un compte?',
        CREATE: 'Créer',
        PASSWORD: 'Mot de passe',
        EMAIL: 'Email',
        LAST_NAME: 'Nom',
        FIRST_NAME: 'Prénom',
        FIELD_REQUIRED: "Ce champ est obligatoire",
        INVALID_EMAIL_ADDRESS: "Adresse e-mail non valide",
        CUSTOMER_LOGIN: 'Connexion client',
        NEW_CUSTOMER_SIGN_UP: "Nouveau client? S'enregistrer!",
        ACCOUNT: 'Compte',
        CREATE_ACCOUNT: 'Créer un compte',
        LANGUAGE: 'Langue',
        ENGLISH: 'Anglais',
        FRENCH: 'Français',
        FORGOT_PASSWORD: "Mot de passe oublié ?",
        TITLE_FORGOT_PASSWORD: "Mot de passe oublié",
        NEXT: "Suivant",
        ENTER_CODE: "Saisir le code",
        CONFIRMATION: "Confirmation",
        CHANGE: "Changer",
        PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS: "Mot de passe doit être au moins de 6 caractères",
        PASSWORDS_ARE_NOT_THE_SAME: "Les mots de passe ne sont pas identiques",
        PLEASE_CONFIRM_YOUR_NEW_PASSWORD: 'Veuillez confirmer votre nouveau mot de passe',
        NEW_PASSWORD:"Nouveau mot de passe",
        IS_COMPANY: 'Société',
        COMPANY_NAME: "Raison sociale",

        CELLPHONE: "Mobile",
        CREATE_AN_ACCOUNT: "Créer un compte", 
        OPERATION_CHANGE_PASSWORD_SUCCESS: 'Votre mot de passe a été modifié avec succès !',
        OPERATION_SUCCESS: "Opération effectuée avec succès",
        PRODUCTS_LEFT: (stoke = 0,withNegativeStock=false,articleId) => withNegativeStock && articleId ?  'En stock' :  (stoke > 0 && articleId)  ? `Il reste ${stoke} produits` : 'Epuisé',
        SHOP_NOW: "Acheter",
        SEARCH: "Chercher",
        PRODUCT: 'Produit',
        TOTAL: 'Total',
        QTY: 'Qté',
        REMOVE: 'Supprimer',
        CHECK_OUT: "Passer à la caisse",
        ARTICLE_NOT_FOUND: "Article non trouvé",
        ARTICLE_NOT_ACTIF: "Article non actif",
        ARTICLE_NOT_AVAILABLE_FOR_SALE: "Article non disponible à la vente",
        ARTICLE_NOT_AVAILABLE_ONLINE: "Article non disponible en ligne",
        WEB_ONLINE_START_DATE_BEFORE_TODAY: "L’article n’est pas disponible actuellement",
        WEB_ONLINE_DEADLINE_EXPIRED: "L’article n’est pas disponible actuellement",
        ARTICLE_TYPE_NOT_SIMPLE: "Type d'article non simple", 
        ADRESS_1_FORM: "Adresse 1",
        ADRESS_2_FORM: "Adresse 2",
        ADRESS_3_FORM: "Adresse 3",
        CITY_FORM: "Ville",
        POSTAL_CODE_FORM: "Code postale",
        COUNTRY: "Pays",
        VALIDATE: 'Valider',
        CANCEL: 'Non, annuler', 
        BILLING_ADDRESS: 'Adresse de facturation',
        DELIVERY_ADDRESS: 'Adresse de livraison',
        MY_ACCOUNT: "Mon compte", 
        ECOMMERCE_IS_NOT_CONFIGURED_FOR_THIS_STORE :"E-commerce n'est pas configuré",
       SOMETHING_WENT_WRONG:"Oups, quelque chose s'est mal passé !",
       REFRESH:"Actualiser",
       OPERATION_FAILED: "Opération échouée",
       DECLINATION_PRINCIPLE_PRICE:({MaskOnBlur=()=>null,
        minDeclinationPrinciplePriceInclTax,  
        maxDeclinationPrinciplePriceInclTax, symbol,precision})=>`De ${MaskOnBlur(minDeclinationPrinciplePriceInclTax, symbol,precision)} à ${MaskOnBlur(maxDeclinationPrinciplePriceInclTax, symbol,precision)}`,
   
        DELETE_LIGNE: "Oui, supprimer la ligne",
        CANCEL_LIGNE: "Non, annuler",
        CONFIRM_DIALOG_TITLE_BLOCK: "Etes vous sûr de vouloir supprimer la ligne ?",
        CONFIRM_VALIDATION_BUTTON: "Oui, valider",
        WOULD_YOU_REALLY_LIKE_TO_CONFIRM_YOUR_CART:"Voulez-vous vraiment valider votre panier ?",
        YOU_HAVE_NO_ITEMS_IN_YOUR_CART:"Vous n'avez aucun article dans votre panier",
        LOGOUT:"Me déconnecter",
        COPY_INTO_THE_DELIVERY_ADDRESS: "Copier dans l'adresse de livraison",
    NO_TEXT_LABEL: "Aucune option",
    PRODUCT_ADDED_SUCCESSFULLY:"Produit ajouté avec succès",
    ERROR_AVAILABLE_STOCK:"Stock Indisponible",
    ERROR_SELECT_VARIANT_VALUE:"Sélectionner une option dans la liste disponible des valeurs de variables",
    HOME: "Accueil",
    YOUR_PAYMENT_HAS_BEEN_SUCCESSFULLY_PROCESSED:"Votre paiement a été effectué avec succès", 
    CONTINUE_SHOPPING:"Poursuivre les Achats",
    TRY_AGAIN:"Réessayer",
    YOUR_PAYMENT_HAS_FAILED:'Votre paiement a échoué. Veuillez vérifier les informations et réessayer',
    }, 
    ENGLISH: {
        DESCRIPTION: "Description",
        FREE_IN_N_HOURS_FROM: (days = 2, price = 300,symbol,precision) => `DELIVERY DEADLINE ${days}d`,/** FROM ${MaskOnBlur(price, symbol,precision)} */
        ADD_TO_CART: 'Add to cart',
        REFERENCE: (ref) => `Reference : ${ref}`,
        SIGN_IN: 'Sign in',
        ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
        CREATE: 'Create',
        PASSWORD: 'Password',
        EMAIL: 'Email',
        LAST_NAME: 'Last name',
        FIRST_NAME: 'First name',
        FIELD_REQUIRED: "This field is required",
        INVALID_EMAIL_ADDRESS: 'Invalid email address',
        CUSTOMER_LOGIN: 'Customer Login',
        NEW_CUSTOMER_SIGN_UP: 'New Customer? Sign Up!',
        ACCOUNT: 'Account',
        CREATE_ACCOUNT: 'Create an account',
        LANGUAGE: 'language',
        ENGLISH: 'English',
        FRENCH: 'French',
        FORGOT_PASSWORD: "Forgot password ?",
        TITLE_FORGOT_PASSWORD: "Forgot password",
        NEXT: "Next",
        ENTER_CODE: "Enter the code",
        CONFIRMATION: "Confirmation",
        CHANGE: "Change",
        PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS: "Password must be at least 6 characters",
        PASSWORDS_ARE_NOT_THE_SAME: "Passwords are not the same",
        PLEASE_CONFIRM_YOUR_NEW_PASSWORD: 'Please confirm your new password', 
        NEW_PASSWORD:"New password",
        IS_COMPANY: 'Company',
        COMPANY_NAME: "Company name", 
        CELLPHONE: "Cell phone",
        CREATE_AN_ACCOUNT: "Create an account", 
        OPERATION_SUCCESS: "Operation completed successfully",
        OPERATION_CHANGE_PASSWORD_SUCCESS: 'Your password has been successfully changed !', 
        PRODUCTS_LEFT: (stoke = 0,withNegativeStock=false,articleId) =>  withNegativeStock && articleId ?  'In stock' : (stoke > 0 && articleId) ? `There are ${stoke} products left` : 'Sold Out',
        SHOP_NOW: "Shop Now",
        SEARCH: "Search",
        PRODUCT: 'Product',
        TOTAL: 'Total',
        QTY: 'Qty',
        REMOVE: 'Remove',
        CHECK_OUT: "Check Out",
        ARTICLE_NOT_FOUND: "Article not found",
        ARTICLE_NOT_ACTIF: "Article not active",
        ARTICLE_NOT_AVAILABLE_FOR_SALE: "Article not available for sale",
        ARTICLE_NOT_AVAILABLE_ONLINE: "Article not available online",
        WEB_ONLINE_START_DATE_BEFORE_TODAY: "Article is not currently available",
        WEB_ONLINE_DEADLINE_EXPIRED: "Article is not currently available",
        ARTICLE_TYPE_NOT_SIMPLE: "Article type not simple", 
        ADRESS_1_FORM: "Adress 1",
        ADRESS_2_FORM: "Adress 2",
        ADRESS_3_FORM: "Adress 3",
        POSTAL_CODE_FORM: "Postal Code",
        CITY_FORM: "City",
        COUNTRY: "Country",
        VALIDATE: 'Validate',
        CANCEL: 'No, cancel',
        BILLING_ADDRESS: 'Billing address',
        DELIVERY_ADDRESS: 'Delivery address',
        MY_ACCOUNT: "My account", 

        ECOMMERCE_IS_NOT_CONFIGURED_FOR_THIS_STORE:"E-commerce is not configured",
       SOMETHING_WENT_WRONG:  "Oops, something went wrong!",
       REFRESH:"Refresh",
       OPERATION_FAILED: "Operation failed",
       DECLINATION_PRINCIPLE_PRICE:({MaskOnBlur=()=>null, 
        minDeclinationPrinciplePriceInclTax,  
        maxDeclinationPrinciplePriceInclTax, symbol,precision})=>`From ${MaskOnBlur(minDeclinationPrinciplePriceInclTax, symbol,precision)} to ${MaskOnBlur(maxDeclinationPrinciplePriceInclTax, symbol,precision)}`,
        DELETE_LIGNE: "Yes, delete the row",
        CANCEL_LIGNE: "No, cancel",
        CONFIRM_DIALOG_TITLE_BLOCK: "Are you sure you want to delete the row ?",
        CONFIRM_VALIDATION_BUTTON: "Yes, validate",
        WOULD_YOU_REALLY_LIKE_TO_CONFIRM_YOUR_CART:"Would you really like to confirm your cart ?",
        YOU_HAVE_NO_ITEMS_IN_YOUR_CART:"You have no items in your cart",
        LOGOUT:"Logout",
        COPY_INTO_THE_DELIVERY_ADDRESS: "Copy into the delivery address",
      NO_TEXT_LABEL: "No options",
      PRODUCT_ADDED_SUCCESSFULLY:"Product added successfully", 
      ERROR_AVAILABLE_STOCK:"Stock Unavailable",
      ERROR_SELECT_VARIANT_VALUE:"Select an option from the available list of variable values",
      HOME: "Home",
      CART:"Cart",
      YOUR_PAYMENT_HAS_BEEN_SUCCESSFULLY_PROCESSED:"Your payment has been successfully processed",
      CONTINUE_SHOPPING:"Continue Shopping",
      TRY_AGAIN:"Try again",
      YOUR_PAYMENT_HAS_FAILED:'Your payment has failed. Please verify the information and try again',
    }
}